.text {
    position: fixed;
    right: 3px;
    bottom: 23px;
    width: 50px;
    font-size: 15px;
    font-weight: bold;
    text-align: right;
    opacity: 0;
    transition: opacity 0.5s;
}

.task {
    position: fixed;
    right: 3px;
    bottom: 8px;
    width: 80px;
    font-size: 10px;
    font-weight: bold;
    text-align: right;
    opacity: 0;
    transition: opacity 0.5s;
}

.task_active {
    opacity: 1 !important;
}

.text_active {
    opacity: 1 !important;
}

.svg_active {
    opacity: 0.2 !important;
}
.svg {
    width: 400px;
    height: 400px;
    position: fixed;
    right: -200;
    bottom: -220;
    opacity: 0;
    transition: opacity 0.5s;
}
