.container {
    transform: translateX(22px);
}
.label {
    font-weight: bold;
    font-size: 15px;
    font-family: 'Hanken Grotesk';
    text-align: right;
}

.value {
    text-align: right;
}
