.entry {
    font-size: 12px;
    line-height: 14px;
    transform: translateX(10px);
    width: 99%;
}

.bullet {
    position: absolute;
    transform: translateY(-5px);
}

.title {
    font-size: 15px;
    font-weight: bold;
}

.container {
    padding-top: 8px;
}
