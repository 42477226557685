.container {
    border-top: 2px dotted #c4c7cc;
    padding-top: 20px;
    width: 100%;
    transform: translateY(20px);
    height: 100px;
}

.entry {
    transform: translateX(8px);
    text-align: center;
    height: 80px;
    width: calc(22%);
    font-family: Hanken Grotesk;
    font-size: 13px;
    font-weight: bold;
}

.titleKpi {
    width: calc(10%) !important;
    text-align: left !important;
}
.desc {
    font-size: 12px;
    font-weight: normal;
}
