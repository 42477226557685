.segmentsTable {
    border-collapse: separate;
    border-spacing: 0px;
    transform: translate(0px, 2px);
    line-height: 29px;
    font-family: Hanken Grotesk;
    border: none;
}

.padding_left {
    padding-left: 10px !important;
}

.thead_th {
    border: 1px dotted #c4c7cc;
    line-height: 29px;
    background-color: #203457 !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: bold !important;
    padding: 10px !important;
    padding-bottom: 1px !important;
    padding-top: 1px !important;
    text-align: left !important;
}

.tbody_td:not(:first-child),
.thead_th:not(:first-child) {
    border-left: none;
    text-align: right !important;
}
.tbody_td:not(:last-child),
.thead_th:not(:last-child) {
    border-right: none;
}

.thead_th:first-child {
    padding-left: 10px !important;
    border-top-left-radius: 10px;
}

.thead_th:last-child {
    padding-right: 10px !important;
    border-top-right-radius: 10px;
}

.tbody_td {
    border: 1px dotted #c4c7cc;
    font-size: 13px !important;
    font-weight: normal;
    padding: 5px;
    line-height: 14px !important;
    text-align: left !important;
    padding-bottom: 7px !important;
    padding-top: 7px !important;
}

.tbody_tr:last-child .tbody_td:first-child {
    border-bottom-left-radius: 10px;
}

.tbody_tr:last-child .tbody_td {
    border-top: 1px dotted #c4c7cc !important;
    font-weight: 900 !important;
}

.tbody_tr:last-child .tbody_td:last-child {
    border-bottom-right-radius: 10px;
}

.value {
    text-align: right !important;
    font-weight: bold !important;
}
