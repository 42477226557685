.tickerBox {
    width: 220px !important;
    transform: translateX(110px);
    position: absolute;
    z-index: 5000;
}

.hoverButton {
    cursor: pointer;
}
.tickerSearch {
    z-index: 5000;
    width: 120px;
    input {
        border-right: none !important;
        border-left: none !important;
        border-radius: 0px !important;
    }
}
.leftEnd {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: white !important;
    border-right: none !important;
}

.rightEnd:hover {
    background-color: #203457 !important;
    color: white !important;
}

.rightEnd {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    background-color: white !important;
    transition:
        background-color 0.3s,
        color 0.3s;
    border-left: none !important;
}
