.header {
    padding: 3;
    background-color: white;
    height: 100px;
    width: 100%;
}

.logo {
    transform: translateX(5);
}
