.panel {
    width: 100%;
    height: 360px;
    border-radius: 25px;
    border: 1px solid #eaecf0;
    background-color: #ffffff;
    margin: 5px !important;
    padding: 8px !important;
    padding-left: 14px !important;
    padding-bottom: 5px !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.content {
    padding-left: 8px !important;
    padding-top: 5px !important;
}

.title {
    font-size: 22px;
    font-family: LustTextItalic;
    font-weight: 500;
    padding-left: 4px;
}
