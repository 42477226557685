.queueContainer {
    position: fixed;
    right: 0px;
    top: 0px;
    text-align: left;
    transform: translateY(-2px);
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards 0.5s;

    table {
        border: none;
        width: 100px;
        border-collapse: separate;
        border-spacing: 0px;
        thead > tr > th {
            text-align: center;
            font-size: 14px;
            font-weight: normal;
            color: white;
            background-color: #203457;
            padding: 4px;
        }
        tbody > tr > td {
            padding: 2px;
            text-align: center;
            border-radius: 0px;
            border: 1px dotted #c4c7cc !important;
        }
        tbody > tr:last-child > td {
            border-radius: 0px 0px 0px 10px !important;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
