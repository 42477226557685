.reportDate {
    font-size: 12px;
    font-family: Hanken Grotesk;
    transform: translate(30, 80);
    text-align: left;
    font-weight: bold;
}

.source {
    transform: translateX(16);
    text-align: left;
    font-weight: bold;
}
