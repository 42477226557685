.title {
    font-size: 15px;
    font-family: Hanken Grotesk;
    font-weight: bold;
}

.row {
    padding-top: 10px;
}

.rowTranscripts {
    font-size: 12px;
    line-height: 14px;
}

.rowTranscripts li {
    padding-top: 5px;
}

.textLink {
    color: #203457;
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
}
