.title {
    font-size: 30px;
    font-family: LustTextMedium;
}

.stock {
	
}

.header {
    height: 100px;
}
